<template>
  <Login v-if="!isLogin" @login-success="onLoginSuccess"></Login>
  <Map v-else></Map>
</template>

<script>
import Login from './components/Login.vue'
import Map from './components/Map.vue'

export default {
  name: 'App',
  components: {
    Login,Map
  },
  data(){
     return {
       isLogin:false
     }
  },
  methods:{
    onLoginSuccess(){
      this.isLogin = true;
    }
  },
  mounted() {
  }
}
</script>

<style>

</style>
