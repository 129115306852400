<template>
  <div class="login">
    <div>
      <div class="chinaMap" ref="chinaMap" v-bind:style="{ width: width + 'px' }" style="height: 500px;">
      </div>
      <div class="backToChina" @click="initCharts()">
        <span v-if="selectType !== 0" >&lt; 点击返回主图</span>
      </div>
    </div>
    <div class="selectDiv">
      <div class="custom-select">
        <select v-model="selectClassType">
          <option v-for="item in this.typeOption" :value="item">{{item}}</option>
        </select>
      </div>
    </div>

    <div class="tableDiv" style="color: white">

      <div class="table rank" v-if="this.selectType === 0 || this.selectType === 1">
        <table v-if="(this.selectType === 0 ? this.getProvinceData() : this.getCityData(this.selectProvince)).length > 0">
          <tr>
            <th>省份</th>
            <th>企业数量</th>
          </tr>
          <tr v-bind:style="{backgroundColor: index % 2 === 0 ? '#002438' : '#000c2d'}" v-for="(item,index) in (this.selectType === 0 ? this.getProvinceData() : this.getCityData(this.selectProvince))">
            <td>{{item.name}}</td>
            <td>{{item.value}}</td>
          </tr>
        </table>
      </div>
      <div style="width: auto" class="table companyDetail" v-else>
        <table v-if="this.getTableData().length > 0 && !this.isShowCompany">
          <tr>
            <th>公司名称</th>
            <th style="min-width: 100px;">区县</th>
            <th style="min-width: 80px">更多</th>
          </tr>
          <tr v-bind:style="{backgroundColor: index % 2 === 0 ? '#002438' : '#000c2d'}"  v-for="(item, index) in this.getTableData()">
            <td>{{ item.companyName }}</td>
            <td>{{ item.country }}</td>
            <td @click="viewCompany(item)" class="view">查看</td>
          </tr>
        </table>
        <div v-else-if="this.isShowCompany" class="detail">
          <div class="back" @click="this.isShowCompany = false">&lt; 返回</div>
          <div v-bind:style="{backgroundColor: index % 2 === 0 ? '#002438' : '#000c2d'}" class="row" v-for="(item, index) in getCurrentCompanyItem()">
            <div class="left">{{ item.key }}</div>
            <div class="right">{{ item.value }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import '../js/map/china.js'
const context = require.context('../js/map/province', false, /\.js$/)
const modules = context.keys().map(context)
export default {
  async mounted() {
    this.screenWidth = document.documentElement.clientWidth;
    if(this.sourceData == null){
      let result = await axios.get('./data/source.json');
      this.sourceData = result.data;
    }
    this.originDataList = this.initData(this.sourceData);
    await this.initCharts()
  },
  data(){
    return {
      mainChartMap: null,
      screenWidth: 0,
      originDataList: [],
      selectType: 0,
      selectProvince: "",
      selectCity: "",
      selectClassType: "全选",
      isShowCompany: false,
      currentCompany: null,
      backgroundColor: '#000c2d', //背景颜色
      rangeColor: ['#0c476e', '#000818'], //数量渐变色
      blockBaseColor: '#0c476e', //块背景色
      blockBorderColor: '#ffffff', //块边缘色
      hoverLabelColor: '#ffffff', //块鼠标移动字颜色
      hoverBlockBorderColor: '#ffffff', //块鼠标移动边框颜色
      hoverBlockColor: '#0c476e', //块鼠标移动块颜色
      labelColor: '#ffffff',//字颜色,
      selectBlockColor: '#ffffff',//选中后块颜色
      sourceData: null
    }
  },
  methods: {
    getCurrentCompanyItem(){
      let province = this.currentCompany.province;
      let directCity = ['北京','上海','重庆','天津'];
      let map = {};
      map["companyName"] = "公司名称";
      map["type"] = "类型";
      map["parentCompany"] = "所属公司";
      map["province"] = "省份";
      map["country"] = "区县";
      map["setUpTime"] = "成立时间";
      map["lastYearTax"] = "上年当地纳税额";
      map["lastYearOutput"] = "上年产值";
      map["situation"] = "企业情况";
      let keySet = ['companyName', 'parentCompany', 'province', 'country', 'setUpTime', 'lastYearTax', 'lastYearOutput', 'situation', 'type']
      if(!directCity.includes(province)){
        map["city"] = "城市";
        keySet.push('city')
      }

      let dataList = [];
      for( let key in this.currentCompany){
        if(keySet.includes(key)){
          dataList.push({
            key:map[key],
            value:this.currentCompany[key] === '' || this.currentCompany[key] === undefined ? '#' : this.currentCompany[key]
          })
        }
      }
      for( let key in this.currentCompany){
        let hasQuaType = key.includes("quaType");
        let hasQuaLevel = key.includes("quaLevel");
        if(hasQuaType){
          dataList.push({
            key:"资质" + key.match(/\d+/)[0] + "类别",
            value:this.currentCompany[key] === '' || this.currentCompany[key] === undefined ? '#' : this.currentCompany[key]
          })
        }
        if(hasQuaLevel){
          dataList.push({
            key:"资质" + key.match(/\d+/)[0] + "等级",
            value:this.currentCompany[key] === '' || this.currentCompany[key] === undefined ? '#' : this.currentCompany[key]
          })
        }
      }


      return dataList;
    },
    viewCompany(item){
      this.isShowCompany = true;
      this.currentCompany = item;
    },
    getTableData(){
      if(this.selectType !== 2){
        return [];
      }
      if(this.selectProvince in this.dataList && this.selectCity in this.dataList[this.selectProvince].childCity){
        return this.dataList[this.selectProvince].childCity[this.selectCity].dataList;
      }
      return [];
    },
    initData(sourceData){
      let dataList = {};
      for (let dataKey in sourceData) {
        let data = sourceData[dataKey];
        let province = data.province;
        let city = data.city;

        if(province in dataList){
          dataList[province].value += 1
          let childCityList = dataList[province].childCity;
          if(city in childCityList){
            childCityList[city].value += 1;
            childCityList[city].dataList.push(data);
          } else {
            childCityList[city] = {
              value: 1,
              dataList: [
                  data
              ]
            }
          }
        } else {
          dataList[province] = {
            value : 1,
            childCity:{}
          }
          dataList[province]["childCity"][city] = {
            value: 1,
            dataList: [
              data
            ]
          }
        }
      }
      return dataList;
    },
    getProvinceData(){
      let provinceData = [];
      for (let dataListKey in this.dataList) {
        provinceData.push({
          name: dataListKey,
          value: this.dataList[dataListKey].value
        })
      }
      provinceData.sort((a, b) => b.value - a.value);
      return provinceData;
    },
    getCityData(province){
      let cityData = [];
      if(province in this.dataList){
        for (let childCityKey in this.dataList[province].childCity) {
          cityData.push({
            name : childCityKey,
            value : this.dataList[province].childCity[childCityKey].value
          })
        }
      }
      cityData.sort((a, b) => b.value - a.value);
      return cityData;
    },
    async initCharts() {
      let that = this;
      if(this.mainChartMap != null){
        this.mainChartMap.clear();
        this.mainChartMap.dispose();
      }
      let provinceChat = this.$echarts.init(this.$refs.chinaMap);
      this.mainChartMap = provinceChat;
      this.selectType = 0;
      this.isShowCompany = false;
      //窗口尺寸改变
      window.addEventListener("resize",function(){
      })
      provinceChat.resize();
      // 绘制图表
      provinceChat.setOption(this.getChinaChatOption());
      provinceChat.on('click', (res)=>{
        if(that.selectType === 0 && res.name != '南海诸岛'){
          provinceChat.clear();
          provinceChat.dispose();
          let cityChat = this.$echarts.init(this.$refs.chinaMap);
          cityChat.clear();
          cityChat.setOption(that.getProvinceChatOption(res.name));
          cityChat.on("click", res=>{
            that.isShowCompany = false;
            that.selectCity = res.name;
          })
          cityChat.on("select", res =>{
              that.selectType = 2;
              that.isShowCompany = false;
          })
          cityChat.on("unselect", res=>{
              that.selectType = 1;
              that.selectCity = "";
              that.isShowCompany = false;
          })
          that.mainChartMap = cityChat;
          that.selectType = 1;
          that.isShowCompany = false;
          that.selectProvince = res.name;
        }
      })
    },
    getChinaChatOption(){
      let position = null;
      if(window.innerWidth < 750){
        position = ['20%', '35%']
      } else{
        position = ['45%', '35%']
      }
      let option = {
        backgroundColor: this.backgroundColor,
        series: [{
          type: 'map',
          map: 'china',
          layoutCenter: position, // 地图布局中心点
          layoutSize: 270,
          roam: true,
          zoom: 3,
          label: this.getLabel(),
          itemStyle: this.getItemStyle(),
          emphasis: this.getEmphasis(),
          data: this.getProvinceData(),
          select: this.getSelectItemStyle(),
        }],
        visualMap: this.getVisualMap(),
        tooltip: this.getTooltip()
      };
      return option;
    },
    getProvinceChatOption(provinceName){
      let option = {
        backgroundColor: this.backgroundColor,
        series: [{
          type: 'map',
          map: provinceName,
          layoutCenter: ['50%', '50%'], // 地图布局中心点
          layoutSize: 270,
          roam: true,
          zoom: 2,
          label: this.getLabel(),
          itemStyle: this.getItemStyle(),
          emphasis: this.getEmphasis(),
          data: this.getCityData(provinceName)
        }],
        visualMap: this.getVisualMap(),
        select: this.getSelectItemStyle(),
        tooltip: this.getTooltip()
      };
      return option;
    },
    getLabel(){
      return {
        show: true,
        color: this.labelColor,
        formatter: this.labelFormatter,
        fontSize: 11,
        textStyle: {
          fontFamily: '微软雅黑',
          fontWeight: 800
        }
      }
    },
    getVisualMap(){
      return [{
        min: 0,
        max: 10,
        inRange: {
          color: this.rangeColor
        }
      }];
    },
    getItemStyle(){
      return {
        areaColor: this.blockBaseColor,
        borderColor: this.blockBorderColor,
        borderWidth: 1,
        shadowColor: 'rgba(255,255,255, 1)',
        shadowBlur: 3
      }
    },
    getSelectItemStyle(){
      return {
        itemStyle: {
          areaColor: this.selectBlockColor,
        },
      }
    },
    getEmphasis(){
      return {
        label: {
          color: this.hoverLabelColor,
          fontSize: 12,
          fontWeight:'bold'
        },
        itemStyle: {
          areaColor: this.hoverBlockColor,
          borderColor: this.hoverBlockBorderColor,
          borderWidth: 2
        }
      };
    },
    labelFormatter(params) {
      let isHas = !isNaN(params.value);
      let text = "";
      if(isHas){
        text = params.name + '\n' + params.value;
      }
      return text;
    },
    getTooltip(){
      return {
        trigger: 'item',  //数据项图形触发
        backgroundColor: "#fff",
        borderWidth: 0,
        formatter: this.tooltipFormatter,
        textStyle: {
          fontFamily: '微软雅黑',
          fontWeight: 800,
          fontSize:12
        },
      }
    },
    tooltipFormatter(params) {
      return "地区: " + params.name + '<br/>' + "数量: " + (isNaN(params.value) ? "0" : params.value);
    },
  },
  computed: {
    dataList(){
      let dataList = this.originDataList;
      if(this.selectClassType !== '全选'){
        let sourceData = [];
        for (let index in this.sourceData) {
          let data = this.sourceData[index]
          if(data.type === this.selectClassType){
            sourceData.push(data)
          }
        }
        dataList = this.initData(sourceData);
      }
      return dataList;
    },
    typeOption(){
      let optionMap = {"全选":1}
      let dataList = this.sourceData;
      if(dataList != null){
        for (let i = 0; i < dataList.length; i++) {
          let data = dataList[i]
          if(data !== null && data.type !== null && data.type !== undefined && data.type !== ''){
            optionMap[data.type]=1;
          }
        }
      }
      let optionList = []
      for(let key in optionMap){
        optionList.push(key)
      }
      return optionList;
    }
  },
  watch:{
    selectClassType(){
      this.initCharts();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.chinaMap{
  margin: auto;
}
.backToChina{
  position: relative;
  top: -487px;
  left: 18px;
  color: #98e8ff;
  font-size: 13px;
  cursor: pointer;
  text-shadow: 0px 0px 30px #ffffff;
}
.backToChina :hover{
  color: #4ee2ff;
}

.selectDiv{
  width: 90%;
  margin: auto;
  margin-top: 10px;
}

.custom-select {
  position: relative;
  display: inline-block;
  background-color: rgba(23,31,54,0.8);
  border-radius: 10px;
  overflow: hidden;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  width: 100%;
}

.custom-select select {
  color: white;
  display: inline-block;
  width: 100%;
  padding: 10px 20px;
  margin: 0;
  border: none;
  outline: none;
  background-color: rgba(23,31,54,1);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

select option {
  border: none;
  outline: none;
}

.custom-select select::-ms-expand {
  display: none;
}

.custom-select:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  pointer-events: none;
}

.custom-select:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 20px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 6px 0 6px;
  border-color: #fff transparent transparent transparent;
  transform: translateY(-50%);
  pointer-events: none;
}

.custom-select select:focus + .selected:before {
  border-color: #fff transparent transparent transparent;
}

.custom-select select:focus + .selected:after {
  opacity: 1;
  transform: translateY(0);
}

.custom-select .selected {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
}

.custom-select .selected:before {
  content: '\25be';
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  color: #fff;
  pointer-events: none;
}

.custom-select .selected:after {
  content: '';
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  height: 0;
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.1);
  transform: translateY(-10px);
  pointer-events: none;
  transition: opacity 0.2s ease, transform 0.2s ease;
}

@media (max-width: 767px) {
  .custom-select {
    font-size: 16px;
  }
}

.tableDiv{
  max-width: 732px;
  margin: auto;
  padding: 20px;
  font-family: "微软雅黑";
  .table{
    width: 100%;
    margin-left: 10px;
    table{
      width: 100%;
      border: 1px solid #ffffff;
      box-shadow: 0px 0px 10px rgba(255, 255, 255, 1);
      tr{
        th{
          padding: 5px;
        }
        td{
          padding: 5px;
          text-align: center;
          vertical-align: middle;
        }
      }
    }
  }
  .companyDetail{
    .detail{
      width: 90%;
      border: 1px solid #ffffff;
      box-shadow: 0px 0px 10px rgba(255, 255, 255, 1);
      padding: 15px;
      .back:hover{
        cursor: pointer;
      }
      .back{
        font-size: 15px;
        margin-bottom: 10px;
        color: #43484b;
      }
      .row{
        padding: 6px;
        .left{
          width: 120px;
          padding-right: 10px;
          float: left;
          margin-right: 10px;
        }
        .left::after{
          content: ":";
        }
      }
    }
    .view{
      color: #ffffff;
      text-shadow: 0px 0px 5px #a9f0ff;
    }
    .view:hover{
      color: white;
      cursor: pointer;
    }
    td{
      padding: 10px;
    }
  }
  .rank{
    tr{
      th{
        width: 50%;
        padding: 5px;
      }
    }
    td{
      padding: 5px;
    }
  }
}

</style>
