<template>
  <div class="login">
    <div class="title">
      企业地图
    </div>
    <div class="inputDiv">
      <input type="text" placeholder="请输入账号" :class="{err : isErr}" v-model="account">
    </div>
    <div class="inputDiv" >
      <input type="password" placeholder="请输入密码" :class="{err : isErr}"  v-model="password">
    </div>
    <div class="inputDiv" >
      <button @click="login">登录</button>
    </div>
  </div>
</template>

<script>
import CryptoJS from 'crypto-js'
import axios from 'axios'
export default {
  name: 'Login',
  data(){
    return{
      account:"",
      password:"",
      isErr:false,
      secret:null
    }
  },
  mounted() {

  },
  methods:{
    async login(){
      if(this.secret === null){
        let result = await axios.get('./data/secret.json');
        this.secret = result.data;
      }
      let seAc = this.encrypt(this.account);
      let sePa = this.encrypt(this.password);
      this.isErr = false;
      console.log(seAc, sePa);
      if(seAc === this.secret.account && sePa === this.secret.password){
        this.$emit('login-success');
        this.isErr = false;
      } else {
        this.isErr = true;
      }
      this.account = "";
      this.password = "";
    },
    encrypt(text){
      return CryptoJS.SHA256("BB--" + text + "--CC").toString();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* 垂直居中展示 */
.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 350px;
  width: 80%;
  max-width: 400px;
  margin: auto;
  margin-top: 100px;
  border-radius: 20px;
  border: 1px solid white;
  box-shadow: 0 0 20px rgba(211, 187, 187, 0.5);
  background-color: rgba(255, 255, 255, 0.1);
}


/* 设置标题样式 */
.title {
  font-size: 36px;
  color: white;
  font-family: "微软雅黑", sans-serif;
  text-align: center;
  margin: 30px 0;
}
.inputDiv{
  width: 100%;
  text-align: center;
}
.inputDiv input{
  width: 60%;
  margin: auto;
  margin-bottom: 30px;
  border: none; /* 去掉边框 */
  outline: none; /* 去掉聚焦时的边框 */
  border-radius: 20px; /* 圆弧边框 */
  border: 1px solid white;
  padding: 10px 20px; /* 设置内边距 */
  font-size: 16px; /* 设置字体大小 */
  color: #333; /* 设置字体颜色 */
  box-shadow: 0px 0px 10px #ccc; /* 添加阴影 */
  transition: box-shadow 0.2s ease-in-out;
}
.err{
  box-shadow: 0px 0px 10px #ff0000 !important; /* 添加阴影 */
}

/* 设置占位符的样式 */
.inputDiv input::placeholder {
  color: #8c8c8c; /* 设置默认文字颜色 */
}

/* 设置按钮样式 */
button {
  width: 73%;
  margin: auto;
  color: white;
  background-color: rgba(255, 255, 255, 0.25);
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  margin-bottom: 30px;
  border: none;
  outline: none;
  font-family: "微软雅黑", sans-serif;
  text-align: center;
  height: 41px;
  border-radius: 25px;
}

/* 设置按钮悬停效果 */
button:hover {
  background-color: #355977;
}
</style>
